<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>
<style>
.total-style {
  border-color: black;
  /* border-style: double; */
  border-top-style: double;
  border-top-width: 4px;
  font-weight: bold;
}
thead{
    border-color: black;

}
</style>

// import store from "@/state/store";

export default [
    
  {
    path: '/areas',
    name: 'areas',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Areas/AreasPage"),
  },
  

  {
    path: '/areas-district-available-days/:id',
    name: 'areas-district-available-days',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/AreasDistrictAvailableDays/AreasDistrictAvailableDaysPage.vue"),
  },
  

  {
    path: '/cancel-reasons',
    name: 'cancel-reasons',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/CancelReasons/CancelReasonsPage"),
  },
  

  {
    path: '/companies',
    name: 'companies',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Companies/CompaniesPage"),
  },
  

  {
    path: '/customers',
    name: 'customers',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Customers/CustomersPage"),
  },
  

  {
    path: '/delivery-app-version',
    name: 'delivery-app-version',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/DeliveryAppVersion/DeliveryAppVersionPage"),
  },
  

  {
    path: '/delivery-men',
    name: 'delivery-men',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/DeliveryMen/DeliveryMenPage"),
  },
  

  {
    path: '/dm-weight-targets/:id',
    name: 'dm-weight-targets',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/DmWeightTargets/DmWeightTargetsPage.vue"),
  },
  
  {
    path: '/district/:id',
    name: 'district',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Areas/district.vue"),
  },

  {
    path: '/reports',
    name: 'reports',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Reports/reportsPage.vue"),
  },

  {
    path: '/order',
    name: 'order',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/newOrder.vue"),
  },

  {
    path: '/',
    name: 'index',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Reports/reportsPage.vue"),
  },
  
  {
    path: '/map-parent/:id',
    name: 'map-parent',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/MapZoner/index.vue"),
  },
  

  {
    path: '/integrations',
    name: 'integrations',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Integrations/IntegrationsPage"),
  },
  

  {
    path: '/inventories',
    name: 'inventories',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Inventories/InventoriesPage"),
  },
  

  {
    path: '/inventory-logs/:id',
    name: 'inventory-logs',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/InventoryLogs/InventoryLogsPage"),
  },
  

  {
    path: '/notifications-sub',
    name: 'notifications-sub',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/NotificationsSub/NotificationsSubPage"),
  },
  

  {
    path: '/order-notes',
    name: 'order-notes',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OrderNotes/OrderNotesPage"),
  },
  

  {
    path: '/order-status',
    name: 'order-status',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OrderStatus/OrderStatusPage"),
  },
  

  {
    path: '/orders',
    name: 'orders',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrdersPage"),
  },
  

  {
    path: '/ref-roles',
    name: 'ref-roles',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/RefRoles/RefRolesPage"),
  },
  

  {
    path: '/user-roles/:id',
    name: 'user-roles',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/UserRoles/UserRolesPage.vue"),
  },
  

  {
    path: '/login',
    name: 'login',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Login/LoginPage.vue"),
  },
  

  {
    path: '/user-roles-permissions',
    name: 'user-roles-permissions',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/UserRolesPermissions/UserRolesPermissionsPage"),
  },
  

  {
    path: '/users',
    name: 'users',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/UsersPage"),
  },
  

  ]


